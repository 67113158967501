import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import LocationIcon from '@material-ui/icons/LocationOnOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import MinusIcon from '@material-ui/icons/RemoveCircleOutline';
import DateRangeSelection from 'UI/dateRangeSelection';
import Autocomplete from 'UI/autocomplete';
import Button from 'UI/button';
import Footer from 'UI/footer';
import Checkbox from 'UI/checkbox';
import { getValidDates, shouldShowDiffToggle, uuid } from 'utils/common';

class RoutePreview extends Component {
  constructor(props) {
    super(props);
    const {
      requirement,
      itineraryActionType,
      originalRoutes = [],
      routes,
    } = this.props;
    this.disabledDateFn = undefined;
    this.showDiffToggle = shouldShowDiffToggle(itineraryActionType, originalRoutes);
    if (itineraryActionType !== 'template') {
      const { tripDates: { from, to } } = requirement;
      this.disabledDateFn = getValidDates(from, to);
    }
    this.state = {
      localError: '',
    };
    if (routes.length === 0) {
      this.handleItemAddition(true);
    }
  }

  handleItemAddition = (initial) => {
    const { routes, onUpdate, requirement } = this.props;
    const { tripDates: { from }, places } = requirement;
    const modRoutes = [...routes];
    let finalFrom = from;
    if (routes.length) {
      const lastRoute = routes[routes.length - 1];
      finalFrom = lastRoute.dates.to;
    }
    modRoutes.push({
      id: uuid(),
      place: {
        value: '',
        valid: false,
      },
      dates: {
        from: finalFrom,
        to: moment(finalFrom).add(1, 'day').toDate(),
      },
      duration: 1,
      routeIndex: routes.length ? routes.length - 1 : 0,
    });
    if (initial && modRoutes.length === 1) {
      modRoutes[0].place.value = places.items[0].name;
      modRoutes[0].place.valid = true;
      modRoutes[0].place.item = places.items[0];
    }
    const { error, errorMsg } = this.handleDurationValidity(modRoutes, modRoutes.length - 1, 1);
    if (error) {
      this.setState({ localError: errorMsg });
      return;
    }
    onUpdate('routes', modRoutes);
  };

  handleDateSelection = (index, dateRange) => {
    const { routes, onUpdate } = this.props;
    const modRoutes = [...routes];
    const route = { ...routes[index] };
    const duration = (moment(dateRange.to).unix() - moment(dateRange.from).unix()) / 86400;
    route.dates = dateRange;
    route.duration = duration;
    modRoutes[index] = route;
    const isOverlapping = this.checkDateOverlap(modRoutes, index);
    if (isOverlapping) {
      this.setState({ localError: 'New dates overlaps with another route dates' });
      return;
    }
    onUpdate('routes', modRoutes);
  };

  handleSelection = (index, item) => {
    const { routes, onUpdate } = this.props;
    const modRoutes = [...routes];
    const route = { ...routes[index] };
    route.place.value = item.name;
    route.place.valid = true;
    route.place.item = item;
    modRoutes[index] = route;
    onUpdate('routes', modRoutes);
  };

  handleChange = (index, field, value) => {
    const {
      routes, onUpdate,
      getSuggestions, requirement,
    } = this.props;
    const modRoutes = [...routes];
    const route = { ...routes[index] };
    if (field === 'place') {
      route.place.value = value;
      route.place.valid = false;
      getSuggestions('cities', value, {
        includeAddressTypes: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2', 'administrative_area_level_3', 'archipelago', 'natural_feature', 'route'],
        excludeAddressTypes: ['lodging'],
        limit: 10,
      });
    } else if (field === 'duration') {
      route.duration = value;
      const { tripDates: { from } } = requirement;
      let lastFrom = from;
      if (index !== 0) {
        lastFrom = routes[index - 1].dates.to;
      }
      const routeFrom = lastFrom;
      route.dates = {
        from: routeFrom,
        to: moment(routeFrom).add(value, 'day'),
      };
    }
    modRoutes[index] = route;
    const isOverlapping = this.checkDateOverlap(modRoutes, index);
    if (isOverlapping) {
      this.setState({ localError: 'New dates overlaps with another route dates' });
      return;
    }
    onUpdate('routes', modRoutes);
  };

  handleDurationChange = (index, action) => {
    const { routes } = this.props;
    const route = { ...routes[index] };
    const newDuration = action === 'add' ? route.duration + 1 : route.duration - 1;
    const { error, errorMsg } = this.handleDurationValidity(routes, index, newDuration);
    if (!error) {
      this.handleChange(index, 'duration', newDuration);
    } else {
      this.setState({ localError: errorMsg });
    }
    // if (action === 'add') {
    //   this.handleChange(index, 'duration', route.duration + 1);
    // } else if (action === 'subtract' && route.duration - 1 >= 0) {
    //   this.handleChange(index, 'duration', route.duration - 1);
    // }
  };

  checkDateOverlap = (routes, index) => {
    if (index === routes.length - 1) {
      return false;
    }
    const route = routes[index];
    const nextRoute = routes[index + 1];
    if (moment(route.dates.to).isAfter(nextRoute.dates.from, 'D')) {
      return true;
    }
    return false;
  };

  handleDurationValidity = (routes, routeIndex, newDuration) => {
    const { itineraryActionType } = this.props;
    let error = false;
    let errorMsg = '';
    if (newDuration < 0) {
      error = true;
      errorMsg = '';
    }
    if (itineraryActionType !== 'template' && !error) {
      const routesLength = routes.length;
      const newEndDate = moment(routes[routeIndex].dates.from).add(newDuration, 'day');
      // last route and still within range
      if (routeIndex + 1 === routesLength && this.disabledDateFn(newEndDate)) {
        error = true;
        errorMsg = 'Duration cannot be more than trip dates';
      } else if (routes[routeIndex + 1]
        && moment(routes[routeIndex + 1].dates.from).isSameOrAfter(newEndDate)) {
        error = true;
        errorMsg = 'Duration overlapping with next route\'s date';
      }
    }
    return {
      error,
      errorMsg,
    };
  };

  handleRouteDelete = (index) => {
    const {
      routes, onUpdate, showSnackbar,
      requirement, itineraryParts,
    } = this.props;
    let attachedParts = false;
    for (let i = 0; i < itineraryParts.length; i++) {
      if (itineraryParts[i].routeIndex === index) {
        attachedParts = true;
      }
    }
    if (attachedParts) {
      showSnackbar('This route has attached itinerary parts, please delete them first', 'error');
      return;
    }
    const { tripDates: { from } } = requirement;
    const modRoutes = [...routes];
    modRoutes.splice(index, 1);
    if (modRoutes.length === 0) {
      modRoutes.push({
        id: uuid(),
        place: {
          value: '',
          valid: false,
        },
        dates: {
          from,
        },
        routeIndex: routes.length,
        duration: 1,
      });
    }
    onUpdate('routes', modRoutes);
  };

  handleTentativeDateChange = (value) => {
    const { onUpdate } = this.props;
    onUpdate('datesAreTentative', value);
  };

  render() {
    const {
      classes, routes, datesAreTentative,
      suggestions, itineraryActionType,
    } = this.props;
    const { localError } = this.state;
    return (
      <div className={classes.container}>
        <div className={classes.heading}>
          <LocationIcon className={classes.routeIcon} />
          <Typography className={classes.headText}>
            Route
          </Typography>
        </div>
        <div className={classes.routesContainer}>
          {routes.map((route, index) => {
            const { dates, duration } = route;
            const { from, to } = dates;
            let dateStr = '';
            if (from && to) {
              const fromDate = moment(from);
              const toDate = moment(to);
              const nights = (toDate.unix() - fromDate.unix()) / 86400;
              dateStr = `${fromDate.format('DD MMM')} - ${toDate.format('DD MMM')} | ${nights} N`;
            }
            let disableFromDate = moment().toDate();
            if (index !== 0) {
              disableFromDate = routes[index - 1].dates.to;
            }
            return (
              <div key={route.id} className={classes.routeRow}>
                <Autocomplete
                  accessor="displayName"
                  placeholder="City"
                  id="_id"
                  value={route.place.value}
                  bodyClass={classes.labelClass}
                  extraClass={classes.inputContainer}
                  inputClass={classes.autoCompleteInput}
                  data={route.place.value ? suggestions.cities : []}
                  onChange={(value) => this.handleChange(index, 'place', value)}
                  onSelected={(item) => this.handleSelection(index, item)}
                />
                <div className={classes.durationSection}>
                  <Typography className={classes.duration}>
                    Duration:
                  </Typography>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.handleDurationChange(index, 'subtract')}
                  >
                    <MinusIcon className={classes.icon} />
                  </IconButton>
                  <InputBase
                    value={duration}
                    className={classes.durationText}
                    type="number"
                    classes={{
                      input: classes.durationInput,
                    }}
                    onChange={(e) => this.handleChange(index, 'duration', e.target.value)}
                  />
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => this.handleDurationChange(index, 'add')}
                  >
                    <AddIcon className={classes.icon} />
                  </IconButton>
                </div>
                {(itineraryActionType !== 'template') ? (
                  <DateRangeSelection
                    extraClass={classes.dateSelection}
                    popupClass={classes.datePopup}
                    value={dateStr}
                    dates={dates}
                    shouldDisableDate={this.disabledDateFn}
                    disableFromDate={disableFromDate}
                    placeholder="Dates"
                    onSelected={(date) => this.handleDateSelection(index, date)}
                  />
                ) : null}
                <IconButton
                  onClick={() => this.handleRouteDelete(index)}
                  className={classes.deleteButton}
                >
                  <DeleteIcon className={classes.deleteIcon} />
                </IconButton>
              </div>
            );
          })}
        </div>
        <Footer errorMsg={localError} align="right">
          <Button
            onClick={this.handleItemAddition}
            variant="outlined"
            className={classes.addButton}
          >
            Add city
          </Button>
        </Footer>
        <Checkbox
          label="Dates not final ?"
          checked={datesAreTentative}
          onChange={() => this.handleTentativeDateChange(!datesAreTentative)}
        />
      </div>
    );
  }
}

RoutePreview.propTypes = {
  classes: PropTypes.object,
  routes: PropTypes.array,
  requirement: PropTypes.object.isRequired,
  errorMsg: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  itineraryActionType: PropTypes.string.isRequired,
  originalRoutes: PropTypes.array,
  itineraryParts: PropTypes.array.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  datesAreTentative: PropTypes.bool,
};

const styles = (theme) => ({
  container: {
    padding: 20,
  },
  heading: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 30,
  },
  routeIcon: {
    width: 24,
    height: 24,
    color: theme.colors.primary,
  },
  headText: {
    padding: '0 10px',
    fontSize: 24,
    color: theme.colors.black,
  },
  routesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 0',
  },
  routeRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 16,
  },
  inputContainer: {
    height: 40,
    flex: 1,
    width: 300,
    paddingRight: 10,
    borderRadius: 4,
  },
  labelClass: {
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
  },
  autoCompleteInput: {
    padding: '6px 5px 6px 15px',
    height: '100%',
    width: '100%',
  },
  durationSection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  duration: {
    fontSize: 12,
    color: theme.colors.textLight,
    padding: '0 10px',
  },
  durationText: {
    color: theme.colors.black,
    fontSize: 14,
    width: 40,
  },
  durationInput: {
    textAlign: 'center',
  },
  iconButton: {
    padding: 4,
  },
  icon: {
    width: 16,
    height: 16,
    color: theme.colors.primary,
  },
  dateSelection: {
    flex: 1,
    marginLeft: 10,
  },
  datePopup: {
    left: -210,
  },
  addButton: {
    width: 120,
    marginRight: 'auto',
    borderRadius: 16,
  },
  deleteButton: {
    marginLeft: 10,
    padding: 4,
  },
  deleteIcon: {
    width: 20,
    height: 20,
    color: theme.colors.red,
  },
});

export default withStyles(styles)(RoutePreview);
