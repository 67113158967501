import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/styles/withStyles';
import { CSSTransition } from 'react-transition-group';
import Button from 'UI/button';
import DotLine from 'UI/dotLine';
import Footer from 'UI/footer';
import Autocomplete from 'UI/autocomplete';
import PartActionButton from 'UI/partActionButton';
import RouteSection from 'UI/routeSection';
import {
  getCardClass, getOriginalPart,
  getDateStr, getTravellerText, getTravellingDates,
} from 'utils/common';
import { CHANGE_TYPES, ITINERARY_ACTIONS_TYPES } from 'utils/consts';
import CreateActivity from './createActivity';
import SelectExperience from './selectExperience';

class ActivityPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateActivity: false,
      activeActivity: {
        mode: '',
        date: '',
        itineraryIndex: -1,
      },
    };
    this.bookingAction = {
      [ITINERARY_ACTIONS_TYPES.REQUEST_BOOKING]: true,
      [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY]: true,
      [ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY_TECH]: true,
    };
    const { suppliers } = this.props;
    this.suppliers = suppliers.map((s) => s.supplier);
  }

  componentDidMount = () => {
    const { suppliers } = this.props;
    this.suppliers = suppliers.map((s) => s.supplier);
  };

  handleChange = (field, value) => {
    this.setState({ [field]: value });
  };

  createActivityHandler = (activity) => {
    const { itineraryParts, onUpdate } = this.props;
    const { activeActivity, routeIndex } = this.state;
    const { itineraryIndex, date } = activeActivity;
    const activityDate = moment(date, 'DD MMM YY').toDate();
    const modActivity = {
      ...activity,
      type: 'ACTIVITY',
      startTimeStamp: activityDate,
      endTimeStamp: activityDate,
      routeIndex,
    };
    if (itineraryIndex === -1) {
      modActivity.supplier = {
        // value: this.suppliers[0].name,
        // valid: true,
        // item: this.suppliers[0],
      };
      onUpdate('add', itineraryIndex, modActivity);
    } else {
      onUpdate('edit', itineraryIndex, { ...itineraryParts[itineraryIndex], ...modActivity });
    }
    this.setState({
      activeActivity: {
        date: '',
        itineraryIndex: -1,
      },
      showCreateActivity: false,
    });
  };

  handleSupplierSelection = (action, index, value) => {
    const { itineraryParts, onUpdate } = this.props;
    const activity = itineraryParts[index];
    const modActivity = { ...activity };
    if (action === 'change') {
      modActivity.supplier = {
        value,
        valid: false,
        item: {},
      };
    } else if (action === 'select') {
      modActivity.supplier = {
        value: value.name,
        valid: true,
        item: value,
      };
    }
    onUpdate('edit', index, modActivity);
  };

  addActivityHandler = (index, date) => {
    this.setState({
      activeActivity: {
        mode: 'new',
        date,
        itineraryIndex: -1,
      },
      routeIndex: index,
      showCreateActivity: true,
    });
  };

  activityActionHandler = (action, itineraryIndex, index, date) => {
    const { onUpdate } = this.props;
    if (action === 'remove') {
      onUpdate('remove', itineraryIndex);
    } else if (action === 'edit') {
      this.setState({
        activeActivity: {
          mode: 'edit',
          date,
          itineraryIndex,
        },
        routeIndex: index,
        showCreateActivity: true,
      });
    } else if (action === 'view') {
      this.setState({
        activeActivity: {
          mode: 'view',
          itineraryIndex,
        },
        routeIndex: index,
        showCreateActivity: true,
      });
    }
  };

  getDayActivities = () => {
    const { itineraryParts, routes } = this.props;
    let count = 0;
    const previewParts = {};
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      const dates = getTravellingDates([route]);
      previewParts[i] = {};
      for (let j = 0; j < dates.length; j++) {
        previewParts[i][dates[j]] = [];
      }
    }
    for (let i = 0; i < itineraryParts.length; i++) {
      if (itineraryParts[i].type === 'ACTIVITY') {
        count++;
        const routeIndex = itineraryParts[i].routeIndex || 0;
        const date = moment(itineraryParts[i].startTimeStamp).format('DD MMM YY');
        let j = 0;
        while (!previewParts[routeIndex + j][date]) j++;
        previewParts[routeIndex + j][date].push({
          ...itineraryParts[i],
          itineraryIndex: i,
          route: routes[routeIndex],
        });
      }
    }
    return {
      activityCount: count,
      activities: previewParts,
    };
  };

  render() {
    const {
      classes, suggestions, itineraryParts, mode,
      itineraryActionType, isFetchingSuggestions,
      getSuggestions, nextHandler, errorMsg, experienceCategories,
      originalParts, routes, version, transferModes,
      requirement,
    } = this.props;
    const { showCreateActivity, activeActivity, routeIndex } = this.state;
    const { itineraryIndex: iIdx, mode: md } = activeActivity;
    const { activities, activityCount } = this.getDayActivities();
    let activity;
    if (iIdx !== -1) {
      activity = itineraryParts[iIdx];
    }
    const originalActivity = getOriginalPart(originalParts,
      activity, itineraryActionType, showCreateActivity);
    return (
      <div className={classes.container}>
        {version === 2 ? (
          <Drawer
            anchor="right"
            open={showCreateActivity}
            onClose={() => this.handleChange('showCreateActivity', false)}
          >
            <SelectExperience
              experienceCategories={experienceCategories}
              itineraryActionType={itineraryActionType}
              mode={md}
              requirement={requirement}
              suggestions={suggestions}
              isFetchingSuggestions={isFetchingSuggestions}
              getSuggestions={getSuggestions}
              transferModes={transferModes}
              activity={activity}
              route={routes[routeIndex]}
              originalActivity={originalActivity}
              onSubmit={this.createActivityHandler}
              onDismiss={() => this.handleChange('showCreateActivity', false)}
            />
          </Drawer>
        )
          : (
            <CSSTransition
              in={showCreateActivity}
              timeout={300}
              classNames="overlay"
              unmountOnExit
            >
              <div className={classes.overlayContainer}>
                <CreateActivity
                  itineraryActionType={itineraryActionType}
                  originalActivity={originalActivity}
                  activity={activity}
                  suggestions={suggestions}
                  getSuggestions={getSuggestions}
                  onSubmit={this.createActivityHandler}
                  onDismiss={() => this.handleChange('showCreateActivity', false)}
                />
              </div>
            </CSSTransition>
          )}
        <div className={classes.body}>
          {mode === 'view' && activityCount === 0 ? (
            <Typography className={classes.emptyText}>No experiences added</Typography>
          ) : routes.map((route, index) => {
            const dateStr = getDateStr(routes[index].dates.from, routes[index].dates.to);
            const activityDates = Object.keys(activities[index]);
            return (
              <div className={classes.activityContainer} key={dateStr}>
                <RouteSection route={routes[index]} />
                {activityDates.map((date, dateIndex) => {
                  const isLast = activityDates.length === dateIndex + 1;
                  return (
                    <div key={date} className={classes.wrapper}>
                      <DotLine isLast={isLast} />
                      <div className={classes.activityContainer}>
                        <Typography className={classes.experienceDate}>
                          {date}
                        </Typography>
                        {(activities[index][date]).map((dayActivity) => {
                          const {
                            experience, from, to, travellers,
                            supplier, proposedChange,
                            transferMode, itineraryIndex,
                          } = dayActivity;
                          const hasTransfer = from?.name && to?.name && transferMode?.title;
                          let descText = '';
                          if (hasTransfer) {
                            descText = ` ${transferMode.title} | ${from.name} -> ${to.name} | `;
                          }
                          let extraCardClass;
                          let isDeleted = false;
                          if (
                            itineraryActionType === ITINERARY_ACTIONS_TYPES.APPROVE_REJECT_ITINERARY
                            || itineraryActionType === ITINERARY_ACTIONS_TYPES.MODIFY_ITINERARY) {
                            extraCardClass = getCardClass(proposedChange.changeType);
                            isDeleted = proposedChange.changeType === CHANGE_TYPES.DELETE;
                          }
                          return (
                            <div
                              key={experience?.title || dayActivity.title}
                              className={clsx(classes.activityCard, classes[extraCardClass])}
                            >
                              <div className={classes.activityDetails}>
                                <Typography
                                  className={classes.activityTitle}
                                >
                                  {experience?.title || dayActivity.title}
                                </Typography>
                                <div className={classes.cardRow}>
                                  <Typography
                                    className={classes.activityDescription}
                                  >
                                    {`Duration: ${experience?.durationInMinutes || dayActivity.duration || 'N/A'} | ${descText}${!!travellers && getTravellerText(travellers)}`}
                                  </Typography>
                                  {((!isDeleted && version !== 2)
                                    || this.bookingAction[itineraryActionType])
                                    ? (
                                      <Autocomplete
                                        data={this.suppliers}
                                        value={supplier.value}
                                        extraClass={classes.autocomplete}
                                        inputClass={classes.autocompleteInput}
                                        accessor="name"
                                        onChange={(val) => this.handleSupplierSelection('change', itineraryIndex, val)}
                                        onSelected={(item) => this.handleSupplierSelection('select', itineraryIndex, item)}
                                      />
                                    ) : null}
                                </div>
                              </div>
                              <PartActionButton
                                actionHandler={this.activityActionHandler}
                                editArgs={[itineraryIndex, index, date]}
                                removeArgs={[itineraryIndex, index]}
                                mode={mode}
                                isDeleted={isDeleted}
                                itineraryActionType={itineraryActionType}
                              />
                            </div>
                          );
                        })}
                        {(mode === 'edit' && itineraryActionType !== 'generate_voucher') ? (
                          <Button
                            variant="outlined"
                            className={classes.addBtn}
                            onClick={() => this.addActivityHandler(index, date)}
                          >
                            + Add Experience?
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        {nextHandler ? (
          <Footer
            errorMsg={errorMsg}
          >
            <Button
              className={classes.saveBtn}
              onClick={nextHandler}
            >
              {mode === 'view' ? 'Next' : 'Save & continue'}
            </Button>
          </Footer>
        ) : null}
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    maxHeight: '74vh',
    backgroundColor: theme.colors.white,
  },
  body: {
    flex: 1,
    padding: '20px 40px',
    overflowY: 'auto',
  },
  overlayContainer: {
    zIndex: 3,
    position: 'absolute',
    top: 64,
    width: '100%',
    height: 'calc(100% - 32px)',
  },
  activityContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 16,
  },
  experienceDate: {
    fontSize: 16,
    color: theme.colors.black,
  },
  emptyText: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.colors.textLight,
    textAlign: 'center',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  addBtn: {
    width: 180,
    margin: '20px 0',
    padding: '5px 20px',
  },
  activityCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px',
    borderRadius: 4,
    border: `1px solid ${theme.colors.border}`,
    marginBottom: 20,
    marginLeft: 16,
    transition: 'box-shadow 400ms',
    '&:hover': {
      boxShadow: theme.extraShadows[1],
    },
  },
  activityImg: {
    width: 60,
    height: 60,
    borderRadius: 6,
    marginRight: 6,
  },
  activityDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
  activityTitle: {
    fontSize: 16,
    color: theme.colors.black,
  },
  activityDescription: {
    fontSize: 14,
    color: theme.colors.textDark,
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 10,
    width: '100%',
  },
  autocomplete: {
    height: 36,
    width: 200,
    marginRight: 120,
    marginLeft: 'auto',
  },
  autocompleteInput: {
    padding: '5px 15px',
  },
  actionArea: {
    position: 'absolute',
    right: 10,
    top: 2,
    display: 'flex',
    flexDirection: 'row',
  },
  removeBtn: {
    fontSize: 10,
    height: 30,
    color: theme.colors.red,
    boxSizing: 'border-box',
    padding: '4px 8px',
    width: 90,
  },
  actionBtn: {
    fontSize: 10,
    height: 30,
    boxSizing: 'border-box',
    padding: '4px 8px',
    marginRight: 8,
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  deletedCard: {
    backgroundColor: theme.colors.redLight,
  },
  editedCard: {
    backgroundColor: theme.colors.primarySelected,
  },
});

ActivityPreview.propTypes = {
  classes: PropTypes.object,
  routes: PropTypes.array.isRequired,
  suppliers: PropTypes.array.isRequired,
  nextHandler: PropTypes.func,
  onUpdate: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  isFetchingSuggestions: PropTypes.bool.isRequired,
  markTouched: PropTypes.func,
  suggestions: PropTypes.object.isRequired,
  errorMsg: PropTypes.string,
  itineraryParts: PropTypes.array.isRequired,
  mode: PropTypes.string,
  itineraryActionType: PropTypes.string.isRequired,
  originalParts: PropTypes.object,
  version: PropTypes.number,
  transferModes: PropTypes.array.isRequired,
  experienceCategories: PropTypes.array.isRequired,
  requirement: PropTypes.object.isRequired,
};

export default withStyles(styles)(ActivityPreview);
