import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import NotificationIcon from '@material-ui/icons/Notifications';
import ContactSupportIcon from '@material-ui/icons/ContactSupportOutlined';
import SpeedIcon from '@material-ui/icons/Speed';
import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import BuildIcon from '@material-ui/icons/BuildOutlined';
import withStyles from '@material-ui/styles/withStyles';
import flynote from 'assets/svg/logo.svg';
import userOutline from 'assets/svg/user.svg';
import logoutImg from 'assets/svg/logout.svg';
import { ROLES } from 'utils/consts';
import Notifications from '../containers/notification/notificationRedux';
import Arrow from './arrow';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.anchorRef = React.createRef();
    this.detailsRef = React.createRef();
    this.state = {
      open: false,
      detailsPopup: false,
    };
  }

  handleClose = (event) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
    document.getElementsByTagName('body')[0].lastElementChild.style.zIndex = 0;
  };

  handleDetailsClose = (event) => {
    if (this.detailsRef.current && this.detailsRef.current.contains(event.target)) {
      return;
    }
    this.setState({ detailsPopup: false });
  };

  handleToggle = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }), () => {
      if (field === 'detailsPopup') {
        setTimeout(this.increaseZIndex, 10);
      }
    });
  };

  increaseZIndex = () => {
    document.getElementsByTagName('body')[0].lastElementChild.style.zIndex = 2;
  };

  showNotifPopup = () => {
    const { open } = this.state;
    if (!open) {
      this.setState({ open: true }, () => {
        setTimeout(this.increaseZIndex, 10);
      });
    } else {
      this.setState({ open: false });
    }
  };

  actionHandler = (action) => {
    const { actionHandler } = this.props;
    this.setState({ detailsPopup: false });
    actionHandler(action);
  };

  analyticsHandler = () => {
    window.open('/analytics', '_blank');
  };

  render() {
    const {
      classes,
      loggedIn,
      user,
    } = this.props;
    const { open, detailsPopup } = this.state;
    const {
      fullName,
      email,
      mobile,
      roles,
      walletBalance,
      // isAdmin,
    } = user;
    // const showAnalyticIcon = window.location.pathname.split('/')[1] !== 'itineraryMaker';
    return (
      <AppBar
        position="static"
        className={classes.container}
        classes={{
          colorPrimary: classes.primaryColor,
        }}
      >
        <div className={classes.imgContainer}>
          <img src={flynote} alt="flynote" className={classes.img} />
        </div>
        <div className={classes.links}>
          {loggedIn ? (
            <div className={classes.loggedInArea}>
              <Tooltip title="Bible">
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    window.open('https://drive.google.com/drive/u/0/folders/1kSQQWbRe20mTn6BTFqjpS21WPE-kvDv1', '_blank');
                  }}
                >
                  <MenuBookOutlinedIcon />
                  <div style={{
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '3px',
                    marginLeft: '2px',
                    marginRight: '10px',
                  }}
                  >
                    Bible
                  </div>
                </IconButton>
              </Tooltip>
              <Tooltip title="Performance">
                <IconButton
                  className={classes.iconButton}
                  style={{ width: '130px' }}
                  onClick={this.analyticsHandler}
                >
                  <SpeedIcon />
                  <div style={{
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    marginTop: '3px',
                    marginLeft: '2px',
                    marginRight: '10px',
                  }}
                  >
                    Performance
                  </div>
                </IconButton>
              </Tooltip>
              <div className={classes.notifArea}>
                <IconButton
                  className={classes.iconButton}
                  aria-label="notification"
                  ref={this.anchorRef}
                  onClick={this.showNotifPopup}
                >
                  <NotificationIcon />
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={this.anchorRef && this.anchorRef.current}
                  transition
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      timeout={0.4}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <div>
                        <ClickAwayListener
                          onClickAway={this.handleClose}
                        >
                          <div>
                            <Notifications />
                          </div>
                        </ClickAwayListener>
                      </div>
                    </Grow>
                  )}
                </Popper>
              </div>
              <Button
                className={classes.profile}
                ref={this.detailsRef}
                onClick={() => this.handleToggle('detailsPopup')}
              >
                <div className={classes.userSection}>
                  <Typography className={classes.name}>
                    {fullName}
                  </Typography>
                  {/* <Typography className={classes.role}>
                    {`Role - ${isAdmin ? 'Admin' : 'Expert'}`}
                  </Typography> */}
                </div>
                <img src={userOutline} alt="user" className={classes.userIcon} />
              </Button>
              <Popper
                open={detailsPopup}
                anchorEl={this.detailsRef && this.detailsRef.current}
                transition
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    timeout={0.4}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <div>
                      <ClickAwayListener
                        onClickAway={this.handleDetailsClose}
                      >
                        <div className={classes.detailsPaper}>
                          <Arrow />
                          <div className={classes.userDetails}>
                            <img alt=" " src={userOutline} className={classes.userAvatar} />
                            <div className={classes.details}>
                              <Typography className={classes.email}>{email}</Typography>
                              <Typography className={classes.mobile}>{mobile}</Typography>
                              <Typography className={classes.walletBalance}>
                                Wallet Balance: <b>₹{walletBalance / 100}</b>
                              </Typography>
                            </div>
                          </div>
                          {/* <Button
                            className={classes.listItem}
                          >
                            <img src={leadsImg} alt=" " className={classes.leadsImg} />
                            <Typography className={classes.listText}>Leads per day</Typography>
                          </Button> */}
                          {/* <Button
                            onClick={() => this.actionHandler('feedback')}
                            className={classes.listItem}
                          >
                            <img src={feedbackImg} alt=" " className={classes.feedbackImg} />
                            <Typography className={classes.listText}>Feedback</Typography>
                          </Button> */}
                          <Button
                            onClick={() => this.actionHandler('help&support')}
                            className={classes.listItem}
                          >
                            <ContactSupportIcon className={classes.helpSupport} />
                            <Typography className={classes.listText}>Help & support</Typography>
                          </Button>
                          {roles.includes(ROLES.TEAM_LEAD) || roles.includes(ROLES.ADMIN)
                            || roles.includes(ROLES.FILTRATION_TEAM_LEAD) ? (
                              <Button
                                onClick={() => this.actionHandler('expertControl')}
                                className={classes.listItem}
                              >
                                <BuildIcon className={classes.helpSupport} />
                                <Typography className={classes.listText}>Expert control</Typography>
                              </Button>
                            ) : null}
                          <Button
                            onClick={() => this.actionHandler('logout')}
                            className={classes.listItem}
                          >
                            <img src={logoutImg} alt=" " className={classes.logoutImg} />
                            <Typography className={classes.listText}>Logout</Typography>
                          </Button>
                        </div>
                      </ClickAwayListener>
                    </div>
                  </Grow>
                )}
              </Popper>
            </div>
          ) : null}
        </div>
      </AppBar>
    );
  }
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 56,
    padding: '10px 40px',
    boxSizing: 'border-box',
  },
  primaryColor: {
    borderRadius: 0,
    background: 'linear-gradient(90deg, #3AAEA9 0%, #16242A 100%)',
  },
  imgContainer: {
    height: 32,
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    height: 26,
  },
  links: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  loggedInArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  action: {
    backgroundColor: 'transparent',
    padding: '0.25rem 0.5rem',
    color: theme.colors.white,
  },
  userSection: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 10,
  },
  name: {
    color: theme.colors.white,
    fontSize: 14,
    fontWeight: 'bold',
  },
  role: {
    fontSize: 12,
    fontWeight: 300,
    color: theme.colors.white,
  },
  iconButton: {
    width: 40,
    height: 40,
    marginLeft: 10,
    borderRadius: '50%',
    color: theme.colors.white,
    minWidth: 0,
    minHeight: 0,
  },
  profile: {
    marginLeft: 20,
    minHeight: 0,
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none',
    padding: '0.25rem 0.5rem',
  },
  userIcon: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    width: 38,
    height: 38,
    borderRadius: '50%',
    color: theme.colors.white,
  },
  notifArea: {
    position: 'relative',
  },
  notif: {
    marginTop: 6,
  },
  detailsPaper: {
    width: 330,
    minHeight: 160,
    backgroundColor: theme.colors.white,
    paddingTop: 20,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: 10,
    zIndex: 2,
    marginTop: 18,
    border: `1px solid ${theme.colors.textLight_1}`,
    boxShadow: '0 6px 10px 0 rgba(0,0,0,0.24)',
  },
  userDetails: {
    display: 'flex',
    paddingBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    borderBottom: `1px solid ${theme.colors.border}`,
  },
  details: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: 20,
  },
  userAvatar: {
    width: 40,
    height: 40,
    backgroundColor: theme.colors.primaryBackground,
    borderRadius: '50%',
  },
  mobile: {
    fontSize: 12,
  },
  email: {
    fontSize: 14,
  },
  walletBalance: {
    fontSize: 12,
    color: '#140025',
    paddingTop: '4px',
  },
  listItem: {
    display: 'flex',
    flex: 1,
    outline: 'none',
    border: 'none',
    padding: 20,
    cursor: 'pointer',
  },
  leadsImg: {
    width: 30,
    height: 30,
    padding: 6,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.primaryLight,
    borderRadius: '50%',
  },
  feedbackImg: {
    width: 30,
    height: 30,
    padding: 6,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.yellowLight,
    borderRadius: '50%',
  },
  logoutImg: {
    width: 30,
    height: 30,
    padding: 6,
    boxSizing: 'border-box',
    backgroundColor: theme.colors.redLight,
    borderRadius: '50%',
  },
  helpSupport: {
    width: 30,
    height: 30,
    color: theme.colors.black,
  },
  listText: {
    flex: 1,
    color: theme.colors.black,
    fontSize: 18,
    display: 'flex',
    marginLeft: 20,
  },
});

Navbar.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  actionHandler: PropTypes.func.isRequired,
  user: PropTypes.object,
  classes: PropTypes.object,
};

export default withStyles(styles)(Navbar);
