import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment';

const box = (classes, title, value) => {
  return (
    <div className={classes.infoBox}>
      <Typography className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.value}>
        {value}
      </Typography>
    </div>
  );
};

const RequestSummary = ({ request = {} }) => {
  const classes = useStyles();
  let placesText = '-';
  let dates = '-';
  let durationTime = 0;
  let tripText = '-';
  let travellersText = '-';
  let cityName = '-';
  const assignedAt = request.assignedAt ? moment(request.assignedAt).format('DD/MM/YY hh:mm A') : '';
  const createdAt = request.created_at ? moment(request.created_at).format('DD/MM/YY hh:mm A') : '';
  // let docs = '-';
  if (request.requirement) {
    const {
      places,
      duration,
      departureDate,
      origin = [],
      tripTypes = [],
      travellers,
    } = request.requirement;
    placesText = places.map((d) => d.name).join(', ') || 'N/A';
    durationTime = duration;
    dates = departureDate ? moment(departureDate).format('DD MMM YYYY') : 'N/A';
    cityName = origin.map((o) => o.name).join(', ') || '-';
    tripText = tripTypes.join(', ');
    let { adults = 0, children = 0, infants = 0 } = travellers;
    adults = adults === null ? 0 : adults;
    children = children === null ? 0 : children;
    infants = infants === null ? 0 : infants;
    travellersText = `${adults + children + infants} (${adults}adults, ${children} children, ${infants} infants)`;
    // docs = '-';
  }
  return (
    <div className={classes.container}>
      <div className={classes.row}>
        {box(classes, 'Destination', placesText)}
        {box(classes, 'Dates', dates)}
        {box(classes, 'Duration', durationTime)}
      </div>
      <div className={classes.row}>
        {box(classes, 'Travellers', travellersText)}
        {box(classes, 'City', cityName)}
        {box(classes, 'Trip type', tripText)}
      </div>
      {
        assignedAt && (
          <div className={classes.row}>
            {box(classes, 'Assigned at', assignedAt)}
          </div>
        )
      }
      {
        createdAt && (
          <div className={classes.row}>
            {box(classes, 'Created at', createdAt)}
          </div>
        )
      }
      {/* <div className={classes.row}>
        {box(classes, 'Docs', docs)}
        {box(classes, '', '')}
      </div> */}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  infoBox: {
    flex: 1,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  title: {
    color: theme.colors.textDark,
    fontFamily: 'Lato',
    fontSize: 14,
    fontWeight: 300,
  },
  value: {
    color: theme.colors.black,
    fontFamily: 'Lato',
    fontSize: 14,
  },
}));

RequestSummary.propTypes = {
  request: PropTypes.object,
};

export default RequestSummary;
