import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import ReviveIcon from '@material-ui/icons/Autorenew';
import Button from 'UI/button';
import Footer from 'UI/footer';
import Autocomplete from 'UI/autocomplete';
import Header from 'UI/header';

const AddActualSupplier = ({
  onDismiss,
  suggestions,
  getSuggestions,
  bookingOb,
  addActualSupplier,
}) => {
  const classes = useStyles();
  const [supplier, setSupplier] = useState({
    value: bookingOb?.actualSupplier?.name,
    valid: !!bookingOb?.actualSupplier?._id,
    item: bookingOb?.actualSupplier,
  });
  const [actualCostPrice, setActualCostPrice] = useState();
  const [errorMsg, setError] = useState('');
  const handleCpChange = (ev) => {
    setActualCostPrice(ev.target.value);
  };
  const handleSubmit = () => {
    if (!supplier.valid) {
      setError('Please select supplier');
    } else {
      addActualSupplier(bookingOb, supplier.item, actualCostPrice * 100);
    }
  };
  const handleSupplierChange = (value) => {
    getSuggestions('supplier', value);
    setSupplier({
      value,
      valid: false,
    });
  };
  const handleSupplierSelection = (sup) => {
    if (sup) {
      setSupplier({
        value: sup.name,
        valid: true,
        item: sup,
      });
    }
  };
  if (!bookingOb) return null;
  return (
    <div className={classes.container}>
      <Header
        title="Add actual supplier"
        Icon={ReviveIcon}
        onDismiss={onDismiss}
      />
      <div className={classes.body}>
        <div className={classes.autoCompleteWrapper}>
          <Typography>
            Supplier:
            {bookingOb.supplier.name}
          </Typography>
          <br />
          <Autocomplete
            label="Add Actual Supplier"
            value={supplier.value}
            data={suggestions.suppliers}
            accessor="name"
            disabled={!!bookingOb?.actualSupplier}
            extraClass={classes.left}
            inputClass={classes.autocompleteInput}
            onChange={handleSupplierChange}
            onSelected={handleSupplierSelection}
          />
          <div className={classes.toSection}>
            <Autocomplete
              disabled
              extraClass={classes.toSectionAutocomplete}
              inputClass={classes.addOnInput}
              data={suggestions.currencies}
              value={bookingOb.currency.code}
              accessor="code"
              variant="no-outline"
              placeholder={bookingOb.currency.code}
              popupClass={classes.popupClass}
              onChange={() => { }}
              onSelected={() => { }}
            />
            <InputBase
              placeholder="Actual Cost Price"
              value={actualCostPrice}
              type="number"
              onChange={handleCpChange}
              classes={{
                root: classes.inputArea,
              }}
            />
          </div>
        </div>
      </div>
      <Footer errorMsg={errorMsg}>
        <Button
          onClick={handleSubmit}
        >
          Update Supplier
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    minWidth: 420,
    minHeight: 420,
  },
  body: {
    padding: '10px 20px 20px 20px',
    flex: 1,
    flexDirection: 'column',
  },
  autoCompleteWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
    flex: 1,
  },
  label: {
    color: theme.colors.textLight,
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  input: {
    height: 64,
  },
  autocompleteInput: {
    width: '100%',
    padding: '6px 10px',
    height: 40,
  },
  toSection: {
    height: 40,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: `1px solid ${theme.colors.border}`,
    borderRadius: 4,
  },
  toSectionAutocomplete: {
    padding: '0',
    height: 40,
    width: 110,
    borderRight: `1px solid ${theme.colors.border}`,
  },
  wrapper: {
    margin: '10px 0',
  },
  inputArea: {
    padding: '10px 12px',
    boxSizing: 'border-box',
    height: 40,
    width: '100%',
  },
  addOnInput: {
    width: '100%',
    height: '100%',
    padding: '5px 10px',
  },
  dateContainer: {
    border: `1px solid ${theme.colors.border}`,
    height: 40,
    borderRadius: 4,
  },
}));

AddActualSupplier.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  suggestions: PropTypes.object.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  addActualSupplier: PropTypes.func.isRequired,
  booking: PropTypes.object.isRequired,
  bookingOb: PropTypes.object.isRequired,
};

export default AddActualSupplier;
