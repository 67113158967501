import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import makeStyles from '@material-ui/styles/makeStyles';
import Header from 'UI/header';
import Footer from 'UI/footer';
import Button from 'UI/button';
import requestImg from 'assets/svg/request.svg';
import { processRequirement, convertRequirement } from 'utils/common';
import AddTripRequirement from './addTripRequirement';

const UpdateTripDetails = ({
  meta,
  suggestions,
  getSuggestions,
  requirement: initialRequirement,
  onUpdate,
  onDismiss,
  tags,
}) => {
  const classes = useStyles();
  const updateHandler = () => {
    const requirementToSend = convertRequirement(requirement);
    onUpdate({ requirement: requirementToSend, tags: localTags });
  };
  const [requirement, setRequirement] = useState(
    processRequirement(initialRequirement, meta.tripTypes)
  );

  const [localTags, setTags] = useState(tags);

  const tagUpdateHandler = (tripDetail) => {
    if (tripDetail.tags) {
      setTags(tripDetail.tags);
    } else {
      setRequirement(tripDetail);
    }
  }

  return (
    <div className={classes.container}>
      <Header
        img={requestImg}
        title="Edit trip requirements"
        onDismiss={onDismiss}
      />
      <AddTripRequirement
        meta={meta}
        showRemarks={false}
        suggestions={suggestions}
        tripDetails={requirement}
        getSuggestions={getSuggestions}
        onUpdate={tagUpdateHandler}
        tags={localTags}
      />
      <Footer>
        <Button
          onClick={updateHandler}
          className={classes.updateBtn}
        >
          Update
        </Button>
      </Footer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.colors.white,
  },
}));

UpdateTripDetails.propTypes = {
  meta: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  requirement: PropTypes.object.isRequired,
  tags: PropTypes.object,
  getSuggestions: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default UpdateTripDetails;
