import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import clsx from 'clsx';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import MultiSelect from 'UI/multiSelect';
import LabelInput from 'UI/labelInput';
import DateRangeSelection from 'UI/dateRangeSelection';
import Checkbox from 'UI/checkbox';
import TravellerSelection from 'UI/travellerSelection';
import { filterList } from 'utils/common';
import Footer from 'UI/footer';
import Button from 'UI/button';
import RadioOptions from 'UI/radioOptions';

const tagsList = [{
  id: 'DATES_NOT_FINAL',
  name: 'Dates not final',
}, {
  id: 'WALLET_CUSTOMER',
  name: 'Wallet customer',
}];

const tagsMap = tagsList.reduce((acc, tag) => {
  acc[tag.id] = tag.name;
  return acc;
}, {});

class AddTripRequirement extends Component {
  state = {
    isFocused: false,
  };

  handleChange = (field, value) => {
    const { tripDetails, onUpdate } = this.props;
    const modRequirement = { ...tripDetails };
    const { [field]: itm } = modRequirement;
    if (itm.constructor.name === 'Object' && field !== 'travellers') {
      modRequirement[field] = {
        ...itm,
        value,
        valid: false,
      };
    } else {
      modRequirement[field] = value;
    }
    if (field === 'flightsBooked' && value) {
      modRequirement.isFlexible = false;
    } else if (field === 'isFlexible' && value) {
      modRequirement.flightsBooked = false;
    }
    onUpdate(modRequirement);
  };

  handleDateSelection = (date, dateStr) => {
    const { tripDetails, onUpdate } = this.props;
    const { from, to } = date;
    let changes = {};
    if (from && to) {
      changes = {
        duration: `${moment(to)
          .diff(moment(from)) / 86400000}`,
      };
    }
    const modRequirement = {
      ...tripDetails,
      tripDate: dateStr,
      ...changes,
      tripDates: date,
    };
    onUpdate(modRequirement);
  };

  handleItemSelection = (field, accessor, item) => {
    const { tripDetails, onUpdate } = this.props;
    const { [field]: stateField } = tripDetails;
    let modField = {};
    if (stateField.items) {
      const iIndex = stateField.items.findIndex((i) => i[accessor] === item[accessor]);
      if (iIndex === -1) {
        modField = {
          value: '',
          valid: true,
          items: [item, ...stateField.items],
        };
      }
    } else {
      modField = {
        value: item[accessor],
        valid: true,
        item,
      };
    }
    const modRequirement = {
      ...tripDetails,
      [field]: modField,
    };
    onUpdate(modRequirement);
  };

  handleSearchChange = (field, searchType, value) => {
    const { tripDetails, onUpdate, getSuggestions } = this.props;
    let type = 'countries';
    let extras = {};
    if (searchType === 'cities') {
      type = 'cities';
      extras = { includeAddressTypes: ['locality'] };
    } else if (searchType === 'places') {
      type = 'destinations';
      extras = {
        includeAddressTypes: ['locality', 'country', 'administrative_area_level_1', 'administrative_area_level_2'],
      };
    }
    getSuggestions(type, value, extras);
    const modRequirement = { ...tripDetails };
    const { [field]: stateField } = modRequirement;
    modRequirement[field] = {
      ...stateField,
      value,
    };
    onUpdate(modRequirement);
  };

  removeHandler = (field, accessor, item) => {
    const { tripDetails, onUpdate } = this.props;
    const modRequirement = { ...tripDetails };
    const { [field]: varField } = modRequirement;
    const modField = { ...varField };
    modField.items = modField.items.filter((d) => d[accessor] !== item[accessor]);
    modRequirement[field] = modField;
    onUpdate(modRequirement);
  };

  setFocus = (isFocused) => {
    this.setState({ isFocused });
  };

  removeTagsHandler = (_field, selection) => {
    const { tripDetails, tags, onUpdate } = this.props;
    const index = tags.indexOf(selection.id);
    const modData = [...tags].splice(index, 1)
    onUpdate({
      tags: modData,
      ...tripDetails,
    });
  };

  handleTagsChange = () => { };

  handleTagsSelection = (_field, selection) => {
    const { tripDetails, tags, onUpdate } = this.props;
    const index = (tags || []).indexOf(selection.id);
    if (index >= 0) {
      onUpdate({
        tags: [...tags, selection.id],
        ...tripDetails,
      });
    } else {
      const modData = [...tags];
      modData.splice(index, 1);
      onUpdate({
        tags: modData,
        ...tripDetails,
      });
    }
  }

  addTentative = () => {
    const { tripDetails, tags, onUpdate } = this.props;
    const index = (tags || []).indexOf('DATES_NOT_FINAL');
    if (index === -1) {
      onUpdate({
        tags: [...(tags || []), 'DATES_NOT_FINAL'],
        ...tripDetails,
      });
    } else {
      const modData = [...tags];
      modData.splice(index, 1);
      onUpdate({
        tags: modData,
        ...tripDetails,
      });
    }
  }

  render() {
    const {
      classes,
      meta,
      suggestions,
      // handleSearchChange,
      // handleItemSelection,
      // removeHandler,
      // handleDateSelection,
      // handleChange,
      tripDetails,
      tags,
      extraClass,
      showRemarks = true,
      showFooter = false,
      errorMsg,
      nextHandler,
      showHotness,
      showTags,
    } = this.props;
    const { isFocused } = this.state;
    const {
      origin,
      places,
      tripDate,
      tripDates,
      duration,
      flightsBooked,
      // isFlexible,
      travellers,
      tripTypes,
      remark,
      hotnessLevel,
    } = tripDetails;
    const tripTypesList = filterList(tripTypes.value, meta.tripTypes, ['title']);
    return (
      <div className={classes.container}>
        <div className={clsx(classes.body, extraClass)}>
          <div className={classes.row}>
            <MultiSelect
              label="ORIGIN CITY"
              value={origin.value}
              selections={origin.items}
              extraClass={classes.leadWrapperLabelLeft}
              inputClass={classes.leadInput}
              data={suggestions.cities}
              accessor="name"
              uniqueId="_id"
              renderType="place"
              removeHandler={(item) => this.removeHandler('origin', 'displayName', item)}
              onChange={(val) => this.handleSearchChange('origin', 'cities', val)}
              onSelected={(item) => this.handleItemSelection('origin', 'displayName', item)}
            />
            <MultiSelect
              label="DESTINATION/S"
              selections={places.items}
              value={places.value}
              extraClass={classes.leadWrapperLabelRight}
              data={suggestions.destinations}
              inputClass={classes.leadInput}
              accessor="name"
              uniqueId="_id"
              renderType="place"
              removeHandler={(item) => this.removeHandler('places', 'displayName', item)}
              onChange={(val) => this.handleSearchChange('places', 'places', val)}
              onSelected={(item) => this.handleItemSelection('places', 'displayName', item)}
            />
          </div>
          <div className={classes.noMarginRow}>
            <LabelInput label="TRIP DATES" extraClass={classes.left}>
              <DateRangeSelection
                allowBefore
                value={tripDate}
                dates={tripDates}
                onSelected={(date, dateStr) => this.handleDateSelection(date, dateStr)}
              />
            </LabelInput>
            <LabelInput
              label="DURATION"
              extraClass={classes.right}
              disabledInput
              value={duration}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.checkboxClassL}>
              <Checkbox
                disabled={false}
                checked={flightsBooked}
                label="Flights booked"
                onChange={() => this.handleChange('flightsBooked', !flightsBooked)}
              />
            </div>
            <div className={classes.checkboxClassR}>
              <Checkbox
                disabled={flightsBooked}
                checked={(tags || []).includes('DATES_NOT_FINAL')}
                label="Dates not final"
                onChange={this.addTentative}
              />
            </div>
            {/* <div className={classes.checkboxClassR}>
              <Checkbox
                disabled={flightsBooked}
                checked={isFlexible}
                label="Flexible dates"
                onChange={() => this.handleChange('isFlexible', !isFlexible)}
              />
            </div> */}
          </div>
          <div className={classes.row}>
            <TravellerSelection
              label="NO. OF TRAVELLERS"
              extraClass={classes.leadWrapperLabelLeft}
              travellers={travellers}
              onChange={(val) => this.handleChange('travellers', val)}
            />
            <MultiSelect
              label="TRIP TYPE"
              selections={tripTypes.items}
              value={tripTypes.value}
              extraClass={classes.leadWrapperLabelRight}
              inputClass={classes.leadInput}
              data={tripTypesList}
              accessor="title"
              removeHandler={(item) => this.removeHandler('tripTypes', 'title', item)}
              onChange={(val) => this.handleChange('tripTypes', val)}
              onSelected={(item) => this.handleItemSelection('tripTypes', 'title', item)}
            />
          </div>
          {showHotness ? (
            <div
              className={classes.col}
              onFocus={() => this.setFocus(true)}
              onBlur={() => this.setFocus(false)}
            >
              <Typography
                className={clsx(classes.label, isFocused && classes.focusedLabel)}
              >
                HOTNESS
              </Typography>
              <RadioOptions
                selected={hotnessLevel}
                onChange={(e, option) => this.handleChange('hotnessLevel', option)}
                options={['Cold', 'Warm', 'Hot']}
              />
            </div>
          ) : null}
          {showTags ? (
            <div className={classes.row}>
              <MultiSelect
                label="Tags"
                selections={tags.map((t) => ({ id: t, name: tagsMap[t] }))}
                data={tagsList}
                accessor="name"
                removeHandler={(item) => this.removeTagsHandler('tags', item)}
                onChange={(val) => this.handleTagsChange('tags', val)}
                onSelected={(item) => this.handleTagsSelection('tags', item)}
              />
            </div>
          ) : null}
          {showRemarks ? (
            <div className={classes.row}>
              <LabelInput
                label="REMARKS"
                extraClass={classes.remarksContainer}
                inputProps={{
                  multiline: true,
                  rows: 3,
                  rowsMax: 3,
                }}
                inputClass={classes.inputClass}
                value={remark}
                placeholder="Details about the trip"
                onChange={(val) => this.handleChange('remark', val)}
              />
            </div>
          ) : null}
        </div>
        {showFooter ? (
          <div className={classes.footerContainer}>
            <Footer
              errorMsg={errorMsg}
            >
              <Button
                onClick={nextHandler}
                className={classes.saveBtn}
              >
                Save & continue
              </Button>
            </Footer>
          </div>
        ) : null}
      </div>
    );
  }
}

const styles = (theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  body: {
    flex: 1,
    width: 520,
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px 0 40px',
    '@media only screen and (max-height: 700px)': {
      maxHeight: 400,
      overflowY: 'auto',
    },
  },
  row: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30,
  },
  noMarginRow: {
    // flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  leadInput: {
    padding: '0px 5px 0px 15px',
    height: '100%',
    width: '100%',
  },
  leadWrapperLabelRight: {
    flex: 1,
    borderRadius: 4,
    // width: '100%',
    maxWidth: '50%',
    marginLeft: 10,
  },
  leadWrapperLabelLeft: {
    flex: 1,
    borderRadius: 4,
    maxWidth: '50%',
    // width: '100%',
    marginRight: 10,
  },
  multiInput: {
    height: '100%',
    width: '100%',
  },
  left: {
    flex: 1,
    marginRight: 10,
  },
  right: {
    flex: 1,
    marginLeft: 10,
  },
  checkboxClassL: {
    flex: 1,
    justifyContent: 'flex-start',
  },
  checkboxClassR: {
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 12,
  },
  popupClass: {
    width: 200,
    top: 30,
    left: -20,
  },
  remarksContainer: {
    width: '100%',
  },
  remarks: {
    borderRadius: 4,
    padding: '6px 15px',
    border: `1px solid ${theme.colors.border}`,
    width: '100%',
  },
  inputClass: {
    height: 'auto',
  },
  saveBtn: {
    width: 180,
    fontSize: 14,
    borderRadius: 25,
    fontWeight: 'bold',
  },
  footerContainer: {
    height: 'auto',
    marginTop: 'auto',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
  },
});

AddTripRequirement.propTypes = {
  classes: PropTypes.object,
  meta: PropTypes.object.isRequired,
  suggestions: PropTypes.object.isRequired,
  // handleSearchChange: PropTypes.func.isRequired,
  // handleItemSelection: PropTypes.func.isRequired,
  // removeHandler: PropTypes.func.isRequired,
  // handleDateSelection: PropTypes.func.isRequired,
  // handleChange: PropTypes.func.isRequired,
  tripDetails: PropTypes.object.isRequired,
  tags: PropTypes.object,
  getSuggestions: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onTagsUpdate: PropTypes.func,
  extraClass: PropTypes.string,
  showRemarks: PropTypes.bool,
  showFooter: PropTypes.bool,
  errorMsg: PropTypes.string,
  nextHandler: PropTypes.func,
  showHotness: PropTypes.bool,
  showTags: PropTypes.bool,
};

export default withStyles(styles)(AddTripRequirement);
