import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ChatIcon from '@material-ui/icons/Chat';
import AddIcon from '@material-ui/icons/Add';
import KeyboardReturn from '@material-ui/icons/KeyboardReturn';
import { CSSTransition } from 'react-transition-group';
import { isEqual } from 'utils/common';
import { ROLES } from 'utils/consts';

class Remarks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remarksEditor: false,
      activeRemark: {},
      displayRemarks: {},
      newRemark: '',
    };
    this.position = { top: 0, left: 0 };
    this.remarksContainerRef = React.createRef();
    this.editorRef = React.createRef();
  }

  componentDidMount = () => {
    const { remarks } = this.props;
    const { top, left } = this.remarksContainerRef.current.getBoundingClientRect();
    this.position = { top, left };
    if (remarks && remarks.length) {
      this.groupRemarks();
    }
  };

  componentDidUpdate = (prevProps) => {
    const { remarks } = this.props;
    if (!isEqual(remarks, prevProps.remarks)) {
      this.groupRemarks();
    }
  };

  groupRemarks = () => {
    const { remarks } = this.props;
    const displayRemarks = {};
    for (let i = 0; i < remarks.length; i++) {
      const momentDate = moment(remarks[i].created_at).format('DD MMM YY');
      if (!displayRemarks[momentDate]) {
        displayRemarks[momentDate] = [remarks[i]];
      } else {
        displayRemarks[momentDate].push(remarks[i]);
      }
    }
    this.setState({ displayRemarks, newRemark: '' });
  };

  checkFiltrationRole = () => {
    const { expert } = this.props;
    return expert?.roles && expert.roles.includes(ROLES.FILTRATION);
  }

  toggleState = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  handleChange = (value) => {
    this.setState({ newRemark: value });
  };

  modifyRemark = (date, index, value) => {
    const { displayRemarks } = this.state;
    const modRemarks = { ...displayRemarks };
    const modDates = [...modRemarks[date]];
    modDates[index] = {
      ...modDates[index],
      text: value,
    };
    modRemarks[date] = modDates;
    const activeRemark = {
      _id: modDates[index]._id,
      text: value,
    };
    this.setState({ displayRemarks: modRemarks, activeRemark });
  };

  onEnterPressed = (e, type = '') => {
    const { onUpdate } = this.props;
    const { activeRemark, newRemark } = this.state;
    if (e.key === 'Enter' && e.shiftKey) {
      if (type === 'create') {
        onUpdate({ text: newRemark, type: 'CREATE' });
      } else {
        onUpdate(activeRemark);
      }
    }
  };

  submitRemark = (type) => {
    const { onUpdate } = this.props;
    const { newRemark, activeRemark } = this.state;
    if (type === 'create' && newRemark.trim().length) {
      onUpdate({ text: newRemark, type: 'CREATE' });
    }
    if (type === 'edit' && activeRemark._id) {
      onUpdate(activeRemark);
    }
  };

  render() {
    const {
      remarks, classes,
    } = this.props;
    const { remarksEditor, displayRemarks, newRemark } = this.state;

    let displayRemark = remarks && remarks.length ? remarks[remarks.length - 1].text : 'No remarks yet!';
    if (displayRemark && displayRemark.length > 130) {
      displayRemark = `${displayRemark.slice(0, 127)}...`;
    }
    return (
      <>
        <CSSTransition
          in={remarksEditor}
          timeout={300}
          classNames="remark"
          unmountOnExit
        >
          <div
            ref={this.editorRef}
            className={classes.remarksEditor}
            style={this.position}
            role="button"
            tabIndex="-1"
            onClick={(e) => e.stopPropagation()}
          >
            <Typography className={classes.popupTitle}>Remarks</Typography>
            {Object.keys(displayRemarks).map((date) => {
              return (
                <div key={date} className={classes.remarksSection}>
                  <Typography
                    className={classes.dateTitle}
                  >
                    {moment(date, 'DD MMM YY').format('DD MMM')}
                  </Typography>
                  {displayRemarks[date].map((remark, index) => {
                    return (
                      <InputBase
                        className={classes.input}
                        key={remark._id}
                        multiline
                        readOnly={this.checkFiltrationRole()}
                        // rowsMax={3}
                        endAdornment={!this.checkFiltrationRole() ? (
                          <IconButton
                            className={classes.actionBtn}
                            onClick={() => this.submitRemark('edit')}
                          >
                            <KeyboardReturn className={classes.actionIcon} />
                          </IconButton>
                        ) : null}
                        value={remark.text}
                        onChange={(e) => this.modifyRemark(date, index, e.target.value)}
                        onFocus={() => this.modifyRemark(date, index, remark.text)}
                        onKeyPress={this.onEnterPressed}
                      />
                    );
                  })}
                </div>
              );
            })}
            {!this.checkFiltrationRole() && (
              <InputBase
                className={classes.newRemark}
                classes={{
                  focused: classes.focusedInput,
                }}
                placeholder="Add remark"
                value={newRemark}
                multiline
                // rowsMax={3}
                endAdornment={(
                  <IconButton
                    className={classes.actionBtn}
                    onClick={() => this.submitRemark('create')}
                  >
                    <AddIcon className={classes.actionIcon} />
                  </IconButton>
                )}
                onChange={(e) => this.handleChange(e.target.value)}
                onKeyPress={(e) => this.onEnterPressed(e, 'create')}
              />
            )}
          </div>
        </CSSTransition>
        {remarksEditor && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <div
            className={classes.backdrop}
            tabIndex="-1"
            role="button"
            onClick={() => this.toggleState('remarksEditor')}
          />
        )}
        <div
          className={classes.container}
          ref={this.remarksContainerRef}
        >
          <div className={classes.row}>
            <ChatIcon className={classes.remarkIcon} />
            <Typography className={classes.title}>Remarks</Typography>
            <IconButton
              onClick={() => this.toggleState('remarksEditor')}
              className={classes.iconButton}
            >
              {this.checkFiltrationRole() ? (
                <VisibilityIcon className={classes.editIcon} />
              )
                : (
                  <EditIcon className={classes.editIcon} />
                )}
            </IconButton>
          </div>
          <Typography className={classes.display}>
            {displayRemark}
          </Typography>
        </div>
      </>
    );
  }
}

const styles = (theme) => ({
  container: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    width: 480,
    borderRadius: 6,
    border: `1px solid ${theme.colors.border}`,
    marginBottom: 20,
    '@media only screen and (max-width: 1520px)': {
      width: 360,
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 6,
  },
  remarkIcon: {
    width: 12,
    height: 12,
    color: theme.colors.primary,
  },
  editIcon: {
    width: 12,
    height: 12,
    color: theme.colors.textDark,
  },
  title: {
    flex: 1,
    paddingLeft: 6,
    fontSize: 14,
    color: theme.colors.textDark,
    marginBottom: 4,
  },
  popupTitle: {
    flex: 1,
    padding: '10px 30px 0',
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.colors.textDark,
    textAlign: 'left',
    marginBottom: 10,
  },
  dateTitle: {
    position: 'sticky',
    top: 0,
    width: '100%',
    backgroundColor: theme.colors.border,
    zIndex: 1,
    color: theme.colors.black,
    fontSize: 14,
    padding: '6px 30px',
    boxSizing: 'border-box',
    marginBottom: 10,
  },
  display: {
    display: 'flex',
    textAlign: 'left',
    flexWrap: 'wrap',
  },
  backdrop: {
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    zIndex: 9,
    backgroundColor: 'rgba(0,0,0,0.32)',
  },
  remarksEditor: {
    zIndex: 10,
    minWidth: 512,
    maxHeight: 500,
    overflowY: 'auto',
    paddingBottom: 20,
    boxSizing: 'border-box',
    borderRadius: 6,
    position: 'absolute',
    outline: 'none',
    backgroundColor: theme.colors.white,
  },
  remarksSection: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '5px 15px',
    margin: '6px 20px',
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${theme.colors.white}`,
    '&:hover': {
      border: `1px solid ${theme.colors.border}`,
    },
  },
  newRemark: {
    width: 'calc(100% - 40px)',
    borderRadius: 4,
    margin: '10px 20px',
    padding: '5px 15px',
    boxSizing: 'border-box',
    border: `1px solid ${theme.colors.border}`,
  },
  focusedInput: {
    border: `1px solid ${theme.colors.primary}`,
  },
  actionBtn: {
    padding: 4,
  },
  actionIcon: {
    width: 16,
    height: 16,
    color: theme.colors.primary,
  },
});

Remarks.propTypes = {
  classes: PropTypes.object,
  remarks: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  expert: PropTypes.object,
};

export default withStyles(styles)(Remarks);
